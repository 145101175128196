import "./App.css"



export default function Loading() {
  return(
      <div className="loader">
         <div className="spinerContainer" >
            <span class="spiner"></span>
            <h1>Veuillez patienter</h1>
            <div className="pointsContainer">
                <p className="ptxt">Site en cours de chargement</p>
                <span class="points"></span>
            </div>
        </div>
      </div> 
  );
}

