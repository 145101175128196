import "./App.css"
import { Link } from "react-router-dom";
export default function Legal() {
    

  return(
      
      <div className="Legal-container">
          <h1 className="mentionTitle"> MENTIONS LEGALES </h1>
          <div className='legalMentions'>
              <a href="https://fr.freepik.com/photos-gratuite/the-chaud-ecorce-table_10992029.htm#fromView=search&page=1&position=19&uuid=c10d9c53-3a94-450a-b0e4-9830b9f83409">Image de jcomp sur Freepik</a>
              <a href="https://fr.freepik.com/photos-gratuite/gros-plan-germes-sol_13238957.htm">Freepik</a>
              <a href="https://fr.freepik.com/photos-gratuite/jeunes-femmes-bras-ouverts-heureusement_10992107.htm#fromView=search&page=1&position=8&uuid=07cd528b-000d-4b35-800b-09d2c660e45f">Image de jcomp sur Freepik</a>
              <a href="https://fr.freepik.com/photos-gratuite/vue-dessus-epices-herbes-medicinales-naturelles_11375837.htm#fromView=search&page=1&position=4&uuid=8d162372-6905-4377-8a67-c73abf542e6a">Image de freepik</a>
              <a href="https://fr.freepik.com/photos-gratuite/angle-eleve-du-concept-medecine-naturelle_8845872.htm#fromView=search&page=1&position=47&uuid=8d162372-6905-4377-8a67-c73abf542e6a">Image de freepik</a>
              <a href="https://fr.freepik.com/photos-gratuite/bureau-soigne-papeterie-cote_2419927.htm#fromView=search&page=1&position=19&uuid=6879e735-5b9d-497c-852c-d88ac5ad3156">Image de freepik</a>
              <a href="https://fr.freepik.com/photos-gratuite/gros-plan-petites-feuilles-vertes-buisson_13153498.htm#fromView=search&page=2&position=19&uuid=ee73a1fc-096b-410f-a063-cc85d7111f2e">Image de wirestock sur Freepik</a>
              <Link className='buttonBack' to='/' ><h3 className="buttonBacks">Revenir à l'accueil</h3></Link>
          </div>
      </div> 
  );
}



 