import './App.css';
import {useEffect, useState} from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import {Link} from "react-router-dom";


// première image moins lourde


function Page () {



  useEffect(()=> {
    Aos.init()
  },[])




  const [openMenu, setOpenMenu]= useState(false)
  const changeOpenMenu=() =>setOpenMenu(!openMenu)


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    sendFeedback("template_od2uhc6", {
      name,
      email,
      message,
    });
  };

  const sendFeedback = (templateId, variables) => {

    window.emailjs
      .send("service_6yoh3ul", templateId, variables)
      .then((res) => {
        console.log('success !');
        setName("");
        setEmail("");
        setMessage("");
        document.querySelector('.form-message').innerHTML =
        "Votre email à bien été envoyé !";
      })
      .catch(
        (err) =>
          document.querySelector('.form-message').innerHTML =
            "Une erreur s'est produite, veuillez réessayer.")
  };




  return  (
 
    <div className="App">
        <div className='navbar'>
        <a  href="/#"><img className='logo' src="https://github.com/florentdesmarets/Image-storage/blob/main/8-bit-gif-background/logo3_20_181945%201.png?raw=true" alt="logo" /></a>          
            <div className='titles'>
              <h1>Catherine Serreau</h1>
              <p  className='secondTitle'>- PRATICIENNE NATUROPATHE -</p>
            </div>
                <div  className="basic-burger"onClick={changeOpenMenu}>
                              <span className={openMenu ? "cross-1" : "cross-4" }></span>
                              <span className={openMenu ? "cross-2" : "cross-5" }></span>
                              <span className={openMenu ? "cross-3" : "cross-6" }></span>
                </div>
            <div className={openMenu ? "menu-open" : "menu-close"}>
                  <div className="basic-links-container">
                          <a className='buttons' onClick={changeOpenMenu} href="#profile"><h3 className="bijour">A propos</h3></a>
                          <a className='buttons' onClick={changeOpenMenu} href="#whatisit"><h3 className="bijour">Qu'est-ce que la naturopathie ?</h3></a>
                          <a className='buttons' onClick={changeOpenMenu} href="#deon"><h3 className="bijour">Déontologie</h3></a>
                          <a className='buttons' onClick={changeOpenMenu} href="#price"><h3 className="bijour">Tarifs</h3></a>
                          <a className='buttons' onClick={changeOpenMenu} href="#foot"><h3 className="bijour">Me Contacter</h3></a>
                          <Link className='buttons' to='/mentionlegal' ><h3 className="bijour">Mentions Légales</h3></Link>
                  <a onClick={changeOpenMenu} href="/#"><img className='logo2' src="https://github.com/florentdesmarets/Image-storage/blob/main/8-bit-gif-background/logo3_20_181945%201.png?raw=true" alt="logo" /></a>          
               </div>
            </div>
        </div>
       <div className='backgroundContainer1 paralax1'>
        <div className='backgroundTitles'>
          <p className='backgroundTitle'>Praticienne</p>
          <p className='backgroundTitle'>en Naturopathie</p>
          <p className='backgroundTitle'>Coach Bien être</p>
        </div>
       </div>
       <div className='headband1'>
          <p className='headband1p'>Prévenir les maladies</p>
          <p className='headband1p'>Accroitre sa vitalité</p>
          <p className='headband1p'>Revitalisation</p>
       </div>
       <div id='profile' className='backgroundContainer2 paralax2'>
        <p  className='thirdTitle'>A propos.</p>
        <div className='backgroundPs'>
          <img className='profilePic'data-aos="fade-up-right" src="https://github.com/florentdesmarets/Image-storage/blob/main/8-bit-gif-background/cat.jpg?raw=true" alt="profile" />
          <div data-aos="fade-up-left" className='backgroundPtctn'>
            <p className='backgroundPt'>Comment j'en suis arrivée à être praticienne en naturopathie et coach bien être ?</p>
            <p className='backgroundP'>
                J'ai découvert ma passion pour la naturopathie grâce à ma curiosité naturelle et mon désir de trouver des réponses aux mystères de la vie.
                J'ai eu la chance de rencontrer des personnes qui ont amélioré ma pratique de la naturopathie, en particulier vers les approches quantiques, la nutrition et l'équilibre de la flore digestive.
            </p>
            <p className='backgroundPsupr'>
                Cette quête personnelle m'a permise de développer mes compétences et d'accompagner mes clients avec une approche holistique de la santé, en prenant en compte l'ensemble de leur bien être.
                Aujourd'hui je suis heureuse en tant que naturopathe de mettre mes connaissances et mon expérience au service de ceux qui cherchent à améliorer leur santé et leur bien-être de manière naturelle et durable.
            </p>
          </div>
        </div>
       </div>
       <div className='headband1'>
          <p className='headband1p'>Prévenir les maladies</p>
          <p className='headband1p'>Accroitre sa vitalité</p>
          <p className='headband1p'>Revitalisation</p>
       </div>
       <div id='whatisit' className='backgroundContainer2 paralax3'>
        <p  className='thirdTitle'>Qu'est-ce que la Naturopathie ?</p>
        <div className='backgroundPs'>
        <div data-aos="fade-up-right" className='backgroundPtctn2'>
            <p className='backgroundP'>
            La naturopathie, médecine de prévention qui vous place comme l'artisan de votre santé. C'est vous qui agissez sur votre état de santé.
            </p>
            <p className='backgroundP'>
            Votre praticienne naturopathe est là pour vous aider à activer vos mécanismes d'auto-guérisons et à vous apprendre les principes d'hygiène de vie, base de bonne santé. Pour ce faire, j'établit avec vous un bilan de vitalité, unique, personnel dans lequel je prends en compte vos traits physiques, psychologiques et physiologique et je recherche la cause de vos déséquilibres. Le naturopathe enseigne l'art d'améliorer sa santé par l'hygiène de vie et prévient plus qu'il ne guérit. Il n'est pas médecin et ne procède ni au diagnostic, ni au traitement des maladies.
            </p>
          </div>
          <div data-aos="fade-up-left" className='backgroundPtctn2supr'>
            <p className='backgroundPt'>La naturopathie se montre particulièrement efficace pour :</p>
            <p className='backgroundP'>
            – Prévention des maladies– Accroitre votre vitalité– Nettoyer votre organisme et retrouver votre énergie optimum grâce à la cure de désintoxication et à la revitalisation en fonction de vos paramètres personnels. – Gestion des maux – Mieux gérer toutes les situations de stress, d'anxiété et de déprime. – Solutionner naturellement les maux qui vous gênent au quotidien, y compris les troubles chroniques : digestion, circulation, articulation etc…
            </p>
          </div>
        </div>
      </div>
      <div className='headband1'>
          <p className='headband1p'>Prévenir les maladies</p>
          <p className='headband1p'>Accroitre sa vitalité</p>
          <p className='headband1p'>Revitalisation</p>
       </div>
       <div id='deon' className='backgroundContainer2 paralax4'>
        <p  className='thirdTitle'>Déontologie du Naturopathe.</p>
        <div className='backgroundPs'>
          <div data-aos="fade-up-bottom" className='backgroundPtctn2'>
            <p className='backgroundP'>
            Pratiquer l’écoute bienveillante sans porter de jugement sur l’histoire personnelle du consultant.  Maintenir le secret professionnel et une complète confidentialité de tout ce qui a été dit au cours de la séance.  Continuer à se former afin de maintenir et mettre à jour ses connaissances au top niveau. Ne pas établir de diagnostic, ce dernier étant réserver au médecin. Ne pas modifier ou faire arrêter un traitement médical en cours.
            </p>
          </div>
        </div>
      </div>
      <div className='headband1'>
          <p className='headband1p'>Prévenir les maladies</p>
          <p className='headband1p'>Accroitre sa vitalité</p>
          <p className='headband1p'>Revitalisation</p>
       </div>
       <div id='price' className='backgroundContainer2 paralax5'>
        <p  className='thirdTitle'>Tarifs.</p>
        <div className='backgroundPs'>
          <div data-aos="fade-up-bottom" className='backgroundPtctn3'>
            <p className='backgroundP'>
            Tarifs Naturopathie:
            </p>
            <p className='backgroundP'>
            80 Euros/ 1H30 
            </p>
            <p className='backgroundP'>
            Tarif coaching bien-être:
            </p>
            <p className='txtdtl'>
            (écoute,accompagnement, suivi psycho-émotionnel)
            </p>
            <p className='backgroundP'>
            65 Euros/ 1H
            </p>
          </div>
        </div>
      </div>
      <div className='headband1'>
          <p className='headband1p'>Prévenir les maladies</p>
          <p className='headband1p'>Accroitre sa vitalité</p>
          <p className='headband1p'>Revitalisation</p>
       </div>
       <div id='foot' className='backgroundContainer3 paralax6'>
        <p  className='thirdTitle'>Me Contacter.</p>
        <div className='footCtn'>
        <div data-aos="fade-up-right"className='backgroundPtctn2'>
          <p className='backgroundFoot'>
           Je vous reçois à mon cabinet sur rendez-vous à Rambouillet ou également par téléphone  & par visioconférence, WhatsApp ou Skype.    Vous pouvez me contacter par mail  
            catherineserreau.naturopathe @gmail.com ou directement via le formulaire de mon site! Au plaisir de vous rencontrer.
          </p>
        </div>
        <a data-aos="fade-up-bottom" href="/#"><img className='logo3' src="https://github.com/florentdesmarets/Image-storage/blob/main/8-bit-gif-background/logo3_20_181945%201.png?raw=true" alt="logo" /></a>          
        <form  data-aos="fade-up-left" id="contact-form" className="form" onSubmit={handleSubmit}>
            <input placeholder="Nom"  name="name" type="text" required htmlFor="name" value={name} onChange={e => setName(e.target.value)}/>
            <input placeholder="Email" name="email" type="email" required htmlFor="email" value={email} onChange={e => setEmail(e.target.value)}/>
            <textarea placeholder="Message"  type="text" name="message" htmlFor="message" value={message} onChange={e => setMessage(e.target.value)} />
            <input className="button" id="submit" value="Envoyer"   type="submit" />
            <div className="form-message"></div>
         </form>
        </div>
          <p className='copy'>Copyright Catherine Serreau  © 2024.  Crafted with ♡ by flo</p>
      </div>
    </div>
  )
}

export default Page;

