import "./App.css"



export default function Flo() {
  return(
      <div className="flo">
         <p className="flotxt">
            Hello there
         </p>
      </div> 
  );
}

