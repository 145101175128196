import './App.css';
import 'aos/dist/aos.css';
import { BrowserRouter as Router,Route,Routes} from "react-router-dom";
import Legal from './Legal';
import Page from './Page';
import Loading from './Loader';
import Flo from './flo';




function App() {


  return  (
 
    <div className="App">
      <Loading className="loader"/>
       <Router>
        <Routes>
            <Route exact path='/' element={<Page/>}/>
            <Route path="/mentionlegal" element={<Legal/>}/>
            <Route path='/flo' element={<Flo/>}/>
        </Routes>
      </Router>
    </div>
  )
}

export default App;

